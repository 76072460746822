import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate =
    screenRes.width > 1920 ? 630 : 1020 - (screenRes.width - 1366) * 0.704;

  if (screenRes.width <= 550) {
    currentProps.currentD = "M544 174v576H89V302c0-70 54-128 121-128z";
    currentProps.currentViewbox = "0 0 543.7 750";
    currentProps.currentAspect = "none";
  } else if (screenRes.width <= 768) {
    currentProps.currentD = "M732 174v576H276V302c0-70 54-128 122-128z";
    currentProps.currentViewbox = "0 0 731.5 750";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M1240 93.36C802.05 317.86 633.21 927.9 1 937.14l2946.45-.14V0L1711.52.74l-101.94 2c-104 0-279.32 44.37-369.58 90.62z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      viewBox={currentProps.currentViewbox}
    >
      <path
        ref={currentShape}
        d={currentProps.currentD}
        fill={shapeColor ? shapeColor : "#fff"}
      />
    </svg>
  );
};
