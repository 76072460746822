import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import Media, { useMedia } from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
  }
  @media (max-width: 768px) {
    height: 40px;
  }
  @media (max-width: 480px) {
    height: 250px;
  }
`;
const secondPath = css`
  position: absolute;
  top: 37px;
  height: calc(100% - 37px);
  @media (max-width: 480px) {
    top: 100px;
    height: calc(100% - 100px);
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const xsMedia = useMedia({ query: "(max-width: 480px)" });

  const screenRes = useWindowSize();
  const currTranslate =
    screenRes.width > 1920 ? 630 : 1020 - (screenRes.width - 1366) * 0.704;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio={xsMedia ? "none" : "xMaxYMax slice"}
              viewBox={xsMedia ? "0 0 375.14 429.94" : "0 0 768 179.94"}
            >
              <path
                d={
                  xsMedia
                    ? "M100 70.07a100 100 0 00-100 100v259.87h375.14V0c-19.61 48.64-48.52 70.06-75.47 70.07z"
                    : "M604.88 151.94H0v28h768V0c-42.38 105.13-104.87 151.92-163.12 151.94z"
                }
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
            <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <path
                d="M0,0 H300V300H-300z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          </>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <path
                ref={currentShape}
                d="M1309.2 93.36C871.25 317.86 632.21 927.9 0 937.14l2946.45 1453V0L1710.52.74l-101.94 2c-104.03.01-209.12 44.37-299.38 90.62z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
