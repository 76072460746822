import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { css } from "@emotion/react";
import Media, { useMedia } from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    height: 50px;
    opacity: 1;
  }
  @media (max-width: 480px) {
    height: 250px;
  }
`;
const secondPath = css`
  position: absolute;
  top: 47px;
  height: calc(100% - 47px);
  @media (max-width: 480px) {
    top: 100px;
    height: calc(100% - 100px);
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};
  const xsMedia = useMedia({ query: "(max-width: 480px)" });
  const smMedia = useMedia({ query: "(max-width: 1365px)" });

  if (smMedia) {
    currentProps.currentD =
      "M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37V410.09M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37Z";
    currentProps.currentViewbox = `${Math.round(
      544 - window.innerWidth * 0.4
    )} 0 1920 937`;
    currentProps.currentAspect = "xMidYMax slice";
  } else {
    currentProps.currentD =
      "M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37V410.09M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37Z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        scale: 1,
      },
      {
        scale: 1.58,
        transformOrigin: "122% 78%",
        ease: "power1.out",
      }
    );
  }, []);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio={xsMedia ? "none" : "xMinYMax slice"}
              viewBox={xsMedia ? "0 0 375.14 429.94" : "0 0 768 179.94"}
            >
              <defs>
                <linearGradient
                  id="trapezoid-gradient"
                  x1="800.77"
                  y1="302.51"
                  x2="1920"
                  y2="302.51"
                  gradientTransform="matrix(1 0 0 -1 0 771.01)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#74001d" />
                  <stop offset="1" stopColor="#ba0027" />
                </linearGradient>
              </defs>
              <path
                d={
                  xsMedia
                    ? "M275.14 70.07H75.47C48.52 70.06 19.61 48.64 0 0v429.94h375.14V170.07a100 100 0 00-100-100z"
                    : "M163.12 151.94H768v28H0V0c42.38 105.13 104.87 151.92 163.12 151.94z"
                }
                ref={currentShape}
                fill={shapeColor ? shapeColor : "#ba0027"}
              />
            </svg>
            <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <defs>
                <linearGradient
                  id="trapezoid-gradient2"
                  x1="150"
                  y1="0"
                  x2="150"
                  y2="200"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#ba0027" />
                  <stop offset="1" stopColor="#74001d" />
                </linearGradient>
              </defs>
              <path
                d="M0,0 H300V300H-300z"
                fill={shapeColor ? shapeColor : "url(#trapezoid-gradient2)"}
              />
            </svg>
          </>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <defs>
                <linearGradient
                  id="trapezoid-gradient"
                  x1="800.77"
                  y1="302.51"
                  x2="1920"
                  y2="302.51"
                  gradientTransform="matrix(1 0 0 -1 0 771.01)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#74001d" />
                  <stop offset="1" stopColor="#ba0027" />
                </linearGradient>
              </defs>
              <path
                d="M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37V410.09M1120.12,410.09l.06-31.93c.09-77.35,22.37-118.36,98.61-124.7h47.73c236.83,0,300.57-97.07,396.83-253.46h611.82V937H798.35c37.77-9.51,149.58-52.92,244.86-164.43,52.39-61.35,76.91-127,76.91-190.37Z"
                ref={currentShape}
                fill={shapeColor ? shapeColor : "url(#trapezoid-gradient)"}
              />
            </svg>
          )
      }
    </Media>
  );
};
