import React from "react";
import { css } from "@emotion/react";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
  }
`;
const secondPath = css`
  position: absolute;
  top: 89px;
  height: calc(100% - 88px);
`;

export const Shape = ({ shapeColor, classes }) => {
  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMaxYMin slice"
              viewBox="0 0 906 90"
            >
              <path
                d="M0 90h908c-37.63-44.8-85.9-83.71-186.31-90H0z"
                fill="#73001f" // ako ima druga forma koqto iska cvqt, togava dobavi property kum strapi settings
              />
            </svg>
            <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <defs>
                <linearGradient
                  id="mob_slope_grad"
                  x1="150"
                  y1="51.94"
                  x2="150"
                  y2="250"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#73001f" />
                  <stop offset="1" stopColor="#b8002c" />
                </linearGradient>
              </defs>
              <path d="M0,0 H300V300H-300z" fill="url(#mob_slope_grad)" />
            </svg>
          </>
        ) : (
            ""
          )
      }
    </Media>
  );
};
