import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();

  if (screenRes.width <= 768) {
    currentProps.currentD = "M0 469h1920v469H0z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  } else if (screenRes.width <= 1365) {
    currentProps.currentD = "M950 0H1920v937H950z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD = "M1130 0h1770v937H1130z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  const currTranslate =
    screenRes.width > 1920 ? 630 : 860 - (screenRes.width - 1366) * 0.8;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;
    // if (rowState === "expanded") {
    //   currentTween.current.duration(0.6).play();
    // } else {
    //   currentTween.current.duration(0.3).reverse();
    // }

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      viewBox={currentProps.currentViewbox}
    >
      <path
        fill={shapeColor ? shapeColor : "#fff"}
        ref={currentShape}
        d={currentProps.currentD}
      />
    </svg>
  );
};
