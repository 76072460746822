import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.85;
  overflow: visible;
  path {
    pointer-events: all;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate =
    screenRes.width > 1920 ? 480 : 905 - (screenRes.width - 1270) * 0.7308;

  if (
    (screenRes.width <= 990 && screenRes.width / screenRes.height >= 1) ||
    screenRes.width <= 850
  ) {
    currentProps.currentD =
      "M80.62 491.5H82c125.87-50 226.17-93.4 309-144.23q4.2-2.58 8-5.3V.5H0v458.24q38.4 16.04 80.62 32.76z";
    currentProps.currentViewbox = "0 0 399 492";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M1357 937c-99.62 1.5-196.28-16.69-270.6-71.14-145.65-106.68-270-269-413.36-431.33C816.23 285.64 955.39 39.79 1290.74 0H2900v937z";
    currentProps.currentViewbox = "-60 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;
    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <svg
            className={classes}
            css={svg}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 2 399 492"
          >
            <path
              ref={currentShape}
              d="M399 284.18V.5H0v418.35l80.62 72.65H82z"
              fill={shapeColor ? shapeColor : "#fff"}
            />
          </svg>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="-60 0 1920 937"
            >
              <path
                ref={currentShape}
                d="M1357 937c-99.62 1.5-196.28-16.69-270.6-71.14-145.65-106.68-270-269-413.36-431.33C816.23 285.64 955.39 39.79 1290.74 0H2900v937z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
