import React, { useContext } from "react";
import * as shapes from "./shapes";

import { RowContext } from "../../context/row-context";

const shapeList = Object.keys(shapes);

const RowShape = ({ shape, shapeColor }) => {
  const { rowState } = useContext(RowContext);

  const Shape = shapeList.includes(shape) ? shapes[shape] : null;

  return Shape ? (
    <Shape classes="row-shape" shapeColor={shapeColor} rowState={rowState} />
  ) : (
    ""
  );
};

export default RowShape;