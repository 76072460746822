import React, { useEffect, useRef } from "react";
import kute from "kute.js";
import "kute.js/kute-svg";
import Media from "react-media";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
    height: 600px;
  }
`;
const secondPath = css`
  position: absolute;
  top: 200px;
  height: calc(100% - 200px);
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate = 730 - (screenRes.width - 1366) * 0.407;

  if (screenRes.width <= 550) {
    currentProps.currentD =
      "M490 748c-9.09.2-490 0-490 0V334.94c.08-46.07 19.9-70.49 87.54-74.27h42.32C339.91 260.67 404.6 203.15 490 110z";
    currentProps.currentViewbox = "0 0 490 748";
    currentProps.currentAspect = "none";
  } else if (
    screenRes.width <= 850 &&
    screenRes.width / screenRes.height <= 15 / 9
  ) {
    currentProps.currentD =
      "M2560 937V0H1307s-188.92 253.5-468.95 253.5h-56.43c-90.17 6.33-116.53 47.31-116.62 124.6l-.07 31.9v172c0 63.31-29 128.91-91 190.2C461.24 883.63 285.24 937 285.24 937";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "xMidYMid slice";
  } else {
    currentProps.currentD =
      "M2560 937V0H1307s-188.92 253.5-468.95 253.5h-56.43c-90.17 6.33-116.53 47.31-116.62 124.6l-.07 31.9v172c0 63.31-29 128.91-91 190.2C461.24 883.63 285.24 937 285.24 937";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    if (!currentShape.current) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = kute.fromTo(
        currentShape.current,
        { svgTransform: { scale: 1 } },
        { svgTransform: { scale: 1.385 } },
        {
          transformOrigin: ["66%", "28%"],
          easing: "easingCubicOut",
          duration: 600,
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.start();
    } else {
      currentTween.current.stop();
      kute
        .fromTo(
          currentShape.current,
          { svgTransform: { scale: 1.385 } },
          { svgTransform: { scale: 1 } },
          {
            transformOrigin: ["66%", "28%"],
            easing: "easingQuadraticOut",
            duration: 400,
          }
        )
        .start();
    }
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 375 700"
            >
              <defs>
                <linearGradient
                  id="t_letter_v2_gradient"
                  x1="156.19"
                  y1="256.37"
                  x2="150.99"
                  y2="65.09"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#73001f" />
                  <stop offset="1" stopColor="#b8002c" />
                </linearGradient>
              </defs>
              <path
                d="M0 700h375V0c-61.55 95.53-112.41 152.72-269.87 152.72H70.88C18.12 157 1.36 183.82.17 234.23z"
                ref={currentShape}
                fill={shapeColor ? shapeColor : "#b8002c"}
              />
            </svg>
            <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <path
                d="M0,0 H300V300H-300z"
                fill={shapeColor ? shapeColor : "url(#t_letter_v2_gradient)"}
              />
            </svg>
          </>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <path
                d="M2560 937V0H1307s-188.92 253.5-468.95 253.5h-56.43c-90.17 6.33-116.53 47.31-116.62 124.6l-.07 31.9v172c0 63.31-29 128.91-91 190.2C461.24 883.63 285.24 937 285.24 937"
                ref={currentShape}
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
