import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();

  if (screenRes.width <= 768) {
    currentProps.currentD =
      "M687.82 0c-68.13 1.39-138.58 21.79-189.68 75.45C390.26 188.75 298.15 326 192 498.41c106.19 172.38 198.3 307.72 306.18 421 51.1 53.65 121.55 74.06 189.68 75.45v-497.4z";
    currentProps.currentViewbox = "0 0 687.82 994.88";
    currentProps.currentAspect = "none";
    // } else if (screenRes.width <= 991) {
    //   currentProps.currentD =
    //     "M32 406.3C88.93 546.9 138.31 657.49 196.09 750H800V0H283.8c-31.8 4.76-63.45 22.35-87.25 60.39C138.57 153 89.08 265.32 32 406.3z";
    //   currentProps.currentViewbox = "-250 0 800 750";
    //   currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M1383.47 0H0v937h1220.69c-42-31.2-78.5-75.27-118.87-126.8-77.87-99.28-185.79-264-244.1-357.68 76-86.89 195.72-226.88 249.91-286.5C1183.61 82.42 1267.23 0 1383.47 0zM2247.57 0l-19.26 2.62c-292.81 40-443.78 287.32-568.85 437.07 125.21 163.28 233.75 326.54 360.93 433.85 38.9 32.83 91.2 53.48 140.47 63.46h1500.43V0z";
    currentProps.currentViewbox = "786 0 1920 937";
    currentProps.currentAspect = "none";
  }

  const currTranslate =
    screenRes.width > 1920
      ? 480
      : // ? 480 - (screenRes.width - 1920) * 0.36
        955 - (screenRes.width - 1270) * 0.7308;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      style={{
        position: "relative",
        pointerEvents: "none",
      }}
      opacity={0.9}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      overflow="visible"
      viewBox={currentProps.currentViewbox}
    >
      <path
        style={{
          pointerEvents: "all",
        }}
        ref={currentShape}
        d={currentProps.currentD}
        fill={shapeColor ? shapeColor : "#fff"}
      />
    </svg>
  );
};
