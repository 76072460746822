import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const leftBarPagerow = css`
  position: relative;
  margin-right: calc(30px + (100vw - 1366px) * 0.0407);
  pointer-events: all;
  align-self: flex-start;
  .kti-row.state-expanded & {
    pointer-events: none;
  }
  @media (max-width: 768px) {
    position: absolute;
    margin: 0;
    right: 25px !important;
    top: 0;
    bottom: 0;
    .share-container {
      right: calc(100% + 5px);
      left: initial;
      bottom: 5px;
      top: initial;
    }
    .leftBarWrap {
      height: 100%;
      position: relative;
      .left-bar-svg {
        height: 100%;
        max-height: initial;
      }
      .content-title-wrap {
        bottom: 150px;
        transform: rotate(-90deg) translateX(100%);
        top: initial;
        transform-origin: bottom right;
      }
    }
  }
  .mobile-content & {
    position: absolute;
    right: 10px;
    margin-right: 0;
    .share-container {
      right: calc(100% + 5px);
      left: initial;
      bottom: 5px;
      top: initial;
    }
  }
`;

export const leftBarNews = css`
  position: relative;
  pointer-events: all;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  .kti-row.state-expanded & {
    pointer-events: none;
  }
  .left-bar-svg {
    height: 45vh;
  }
  .content-title-wrap {
    transform: rotate(-90deg) translateY(-100%);
    right: calc(0.9px + (100vw - 1366px) * 0.001805);
    top: 0;
    transform-origin: top right;
    bottom: initial;
    @media (max-width: 1366px) {
      right: 4px;
    }
  }
  @media (max-width: 1365px) and (min-width: 769px) {
    .kti-row & .left-bar-svg {
      height: 370px;
    }
  }
`;

export const LeftBarLine = css`
  height: 67vh;
  width: 22px;
  max-height: 850px;
  .shape-slope_brands &,
  .shape-t_letter_v2 &,
  .shape-t_letter_inverse & {
    height: 75vh;
  }
  .shape-t_letter_inverse_reverse &,
  .single-brand & {
    height: 77vh;
    @media (min-width: 1921px) {
      width: 27px;
    }
  }
  @media (min-width: 1366px) {
    #key-to-success &,
    #our-team &,
    #work-environment &,
    #social-responsibility &,
    #events-calendar & {
      height: calc(481px + (100vw - 1366px) * 0.265);
    }
    .shape-slope &,
    .shape-t_letter_v2_reverse &,
    .shape-k_letter_open_inverse &,
    .kti-row.media-partners & {
      height: 81vh;
      @media (min-width: 1921px) {
        width: 27px;
      }
    }
  }
  @media (max-width: 1365px) {
    .kti-row & {
      height: 47vw;
      width: 20px;
    }
  }
`;

export const LeftBarWrap = styled.div`
  transition: transform 0.31s ease-in 0.1s;
  display: flex;
  flex-flow: column;
  @media (min-width: 1921px) {
    .left-bar-svg {
      width: 25px;
    }
    .shape-slope &,
    .shape-t_letter_v2_reverse &,
    .shape-k_letter_open_inverse &,
    .kti-row.media-partners &,
    .shape-t_letter_inverse_reverse &,
    .single-brand & {
      .share-wrap > svg {
        width: 30px;
        height: 30px;
      }
      .left-button {
        right: -3px;
        font-size: 16px;
        width: 100px;
      }
    }
  }
`;

export const LeftButton = css`
  text-transform: uppercase;
  display: block;
  position: absolute;
  right: -7px;
  padding: 6px 0;
  line-height: 1;
  text-align: center;
  width: calc(63px + (100vw - 1366px) * 0.01264);
  bottom: 37px;
  font-size: calc(13px + (100vw - 1366px) * 0.00216);
  font-family: var(--sec-font);
  font-weight: 300;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  user-drag: none;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
  transition: color 0.3s;
  &:hover,
  &:focus {
    color: #ba0027;
  }
  @media (max-width: 1365px) {
    right: -3px;
    width: 63px;
    font-size: 13px;
  }
`;

export const TitleWrap = styled.div`
  overflow: hidden;
  position: absolute;
  right: calc(-7px + (100vw - 1366px) * 0.01733);
  right: 1.7px;
  bottom: 50%;
  transform: rotate(-90deg) translateX(60%);
  transform-origin: bottom right;
  white-space: nowrap;
  .shape-t_letter_v2_reverse &,
  .kti-row.media-partners & {
    bottom: 57%;
  }
  .shape-t_letter_inverse & {
    bottom: 59%;
  }
  .shape-t_letter_v2 & {
    transform: rotate(-90deg) translateX(50%);
  }
  .shape-slope &,
  .shape-t_letter_v2_reverse & {
    transform: rotate(-90deg) translateX(40%);
  }
  @media (max-width: 1365px) {
    right: 3.7px;
  }
  @media (max-width: 768px) {
    .kti-row:not(.recent-news) & {
      transform: rotate(-90deg) translateX(100%) !important;
      bottom: 130px !important;
      top: initial !important;
      transform-origin: bottom right !important;
    }
  }
`;

export const Title = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-family: var(--sec-font);
  font-weight: 600;
  font-size: calc(43px + (100vw - 1366px) * 0.012963);
  line-height: 1;
  color: ${(props) => props.color};
  line-height: 0.907;
  transition: transform 0.31s ease-in;
`;

export const ShareWrap = styled.button`
  display: flex;
  padding: 7px 0;
  cursor: pointer;
  border: none;
  position: relative;
  background-color: transparent;
  outline: none;
  > svg {
    width: 23px;
    height: 23px;
    fill: currentColor;
    outline: none;
    @media (min-width: 1921px) {
      width: 25px;
      height: 25px;
    }
  }
`;

export const ShareContainer = styled.div`
  position: absolute;
  left: calc(100% + 6px);
  top: 8px;
  overflow: hidden;
  z-index: 20;
  cursor: default;
  .single-brand & {
    left: initial;
    right: calc(100% + 6px);
  }
`;

export const shareIconWrap = css`
  background-color: grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.4s, opacity 0.2s, visibility 0.2s;
  margin-bottom: 5px;
  transition-delay: 0s;
  a {
    padding: 5px;
    display: flex;
    outline: none;
  }
  &.share-links-shown {
    &:nth-of-type(1) {
      transition-delay: 0s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.05s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.1s;
    }
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

export const shareIcon = css`
  width: 12px;
  height: 12px;
  fill: #fff;
`;
