import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
`;
// const secondPath = css`
//   position: absolute;
//   top: 0px;
//   height: 100%;
//   opacity: 0.9;
// `;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate = 970 - (screenRes.width - 1366) * 0.722;

  if (screenRes.width <= 550) {
    currentProps.currentD =
      "M.22 1.15C9.31 1 490 0 490 0v262.06c-.08 46.07-19.85 70.49-87.49 74.27h-42.37C150.09 336.33 85.4 393.85 0 487z";
    currentProps.currentViewbox = "0 0 490 748.2";
    currentProps.currentAspect = "none";
  } else if (
    screenRes.width <= 850 &&
    screenRes.width / screenRes.height <= 15 / 9
  ) {
    currentProps.currentD =
      "M1405 937s-176-53.37-288.69-164.8c-62-61.29-91-126.89-91-190.2V410l-.07-31.9c-.09-77.29-26.45-118.27-116.62-124.6h-56.43C572.16 253.5 383.24 0 383.24 0H0v937z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "xMidYMid slice";
  } else {
    currentProps.currentD =
      "M1405 937s-176-53.37-288.69-164.8c-62-61.29-91-126.89-91-190.2V410l-.07-31.9c-.09-77.29-26.45-118.27-116.62-124.6h-56.43C572.16 253.5 383.24 0 383.24 0H0v937z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        scale: 1,
      },
      {
        scale: 2,
        transformOrigin: "0 58%",
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);
  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <svg
            className={classes}
            css={svg}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMax slice"
            viewBox="0 0 375 700"
          >
            <path
              d="M375 0H0v700c61.55-95.53 112.41-152.72 269.87-152.72h34.25c52.76-4.28 69.52-31.1 70.71-81.51z"
              fill={shapeColor ? shapeColor : "#fff"}
            />
          </svg>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <path
                d="M1405 937s-176-53.37-288.69-164.8c-62-61.29-91-126.89-91-190.2V410l-.07-31.9c-.09-77.29-26.45-118.27-116.62-124.6h-56.43C572.16 253.5 383.24 0 383.24 0H0v937z"
                ref={currentShape}
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
