import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);

  const screenRes = useWindowSize();
  const currTranslate =
    screenRes.width > 1920 ? 240 : 540 - (screenRes.width - 1366) * 0.542;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <svg
            className={classes}
            css={svg}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 0 300 300"
          >
            <defs>
              <linearGradient
                id="rectangularGradient"
                x1="800"
                y1="468.5"
                x2="2720"
                y2="468.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#74001d" />
                <stop offset="1" stopColor="#ba0027" />
              </linearGradient>
            </defs>
            <path
              ref={currentShape}
              fill={shapeColor ? shapeColor : "url(#rectangularGradient)"}
              d="M0 0h300v300h-300z"
            />
          </svg>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <defs>
                <linearGradient
                  id="rectangularGradient"
                  x1="800"
                  y1="468.5"
                  x2="2720"
                  y2="468.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#74001d" />
                  <stop offset="1" stopColor="#ba0027" />
                </linearGradient>
              </defs>
              <path
                ref={currentShape}
                fill={shapeColor ? shapeColor : "url(#rectangularGradient)"}
                d="M800 0h1920v937H800z"
              />
            </svg>
          )
      }
    </Media>
  );
};
