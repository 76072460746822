import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
    overflow: hidden;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);

  const screenRes = useWindowSize();
  const currTranslate = 730 - (screenRes.width - 1366) * 0.407;

  useEffect(() => {
    if (!currentShape.current) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = gsap.fromTo(
        currentShape.current,
        {
          x: 0,
        },
        {
          x: 790,
          duration: 0.6,
          ease: "power1.out",
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.restart();
    } else {
      currentTween.current.pause();
      gsap.fromTo(
        currentShape.current,
        {
          x: 790,
        },
        {
          x: 0,
          duration: 0.4,
          ease: "power1.out",
        }
      );
    }
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <svg
            className={classes}
            css={svg}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            viewBox="0 0 374.67 329"
          >
            <path
              d="M0 328.71v.29h374.67V110C290.37 204.48 241.43 323.93 0 328.71zM0 0v245.07C114.28 150.7 145.21 6.52 374.67.37V0z"
              fill={shapeColor ? shapeColor : "#fff"}
            />
          </svg>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <path
                ref={currentShape}
                d="M1920 937V387.08c-208 223-391.87 464.3-878.62 549.92zM-1920 937H117.9c90.34-16.92 178.81-38.85 258.41-66.39C981 661.4 1023.84 146.57 1669.22 0H-1920z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
