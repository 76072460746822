import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);

  const screenRes = useWindowSize();

  const currTranslate =
    screenRes.width > 1920 ? 750 : 970 - (screenRes.width - 1366) * 0.4;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;
    // if (rowState === "expanded") {
    //   currentTween.current.duration(0.6).play();
    // } else {
    //   currentTween.current.duration(0.3).reverse();
    // }

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      style={{
        position: "relative",
        pointerEvents: "none",
      }}
      opacity={0.9}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      overflow="visible"
      viewBox="0 0 1920 937"
    >
      <path
        style={{
          pointerEvents: "all",
        }}
        fill={shapeColor ? shapeColor : "#fff"}
        ref={currentShape}
        d="M1230 0h1870v937H1230z"
      />
    </svg>
  );
};
