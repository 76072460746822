import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import { useMedia } from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);

  const screenRes = useWindowSize();
  const currTranslate = 730 - (screenRes.width - 1366) * 0.407;
  const isSm = useMedia({ query: "(max-width: 1200px)" });

  useEffect(() => {
    if (!currentShape.current) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = gsap.fromTo(
        currentShape.current,
        {
          x: 0,
        },
        {
          x: 800,
          duration: 0.6,
          ease: "power1.out",
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.restart();
    } else {
      currentTween.current.pause();
      gsap.fromTo(
        currentShape.current,
        {
          x: 800,
        },
        {
          x: 0,
          duration: 0.4,
          ease: "power1.out",
        }
      );
    }
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      viewBox="0 0 1920 937"
    >
      <path
        ref={currentShape}
        d={
          isSm
            ? "M740 434.57C883.28 285.67 1022.49 39.8 1358 0H0v937h1402.77c-92-1.31-180.26-20.52-249.26-71.06C1007.8 759.25 883.45 596.91 740 434.57z"
            : "M880 434.57C1023.28 285.67 1162.49 39.8 1498 0h-2498v937h2542.77c-92-1.31-180.26-20.52-249.26-71.06C1147.8 759.25 1023.45 596.91 880 434.57z"
        }
        fill={shapeColor ? shapeColor : "#fff"}
      />
    </svg>
  );
};
