import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 950px) and (max-aspect-ratio: 15/9) {
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};
  const screenRes = useWindowSize();

  if (screenRes.width <= 1200) {
    currentProps.currentD =
      "M879 91h64c104 8 134 63 135 165v439c0 81 30 164 95 242h747V0H536c78 59 181 91 343 91z";
    currentProps.currentAspect = "none";
    currentProps.currentViewbox = "0 0 1920 937";
  } else {
    currentProps.currentD =
      "M1476.49-245.94c13.94.44 1746.51 1 1746.51 1l-.74 1409.94-1528 .83c-51.37-12.64-202.4-70.15-332-218-71.27-81.31-104.64-168.34-104.64-252.34V467.31l-.08-211c-.12-102.54-30.43-156.9-134.12-165.3h-64.89c-322 0-408.36-128-539.28-335.34z";
    currentProps.currentAspect = "none";
    currentProps.currentViewbox = "0 0 1920 937";
  }

  const currTranslate =
    screenRes.width > 1920 ? 490 : 1020 - (screenRes.width - 1366) * 0.487;

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
        y: 0,
      },
      {
        x: -currTranslate,
        y: 80,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      viewBox={currentProps.currentViewbox}
    >
      <path
        ref={currentShape}
        fill={shapeColor ? shapeColor : "#b87891"}
        d={currentProps.currentD}
      />
    </svg>
  );
};
