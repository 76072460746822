import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate = 730 - (screenRes.width - 1366) * 0.407;

  if (screenRes.width <= 550) {
    currentProps.currentD =
      "M850 172.61C743.63 297.47 671.84 374.45 409 374.45h-53.34c-85.23 5.12-110.14 38.26-110.24 100.78l-.06 25.8v139.11c0 51.21-27.44 104.27-86 153.85C106.52 838.7 49.36 889.26 0 929.81V937h850z";
    currentProps.currentViewbox = "0 0 850 937";
    currentProps.currentAspect = "xMidYMid slice";
  } else if (screenRes.width <= 768) {
    currentProps.currentD =
      "M850 172.61C743.63 297.47 671.84 374.45 409 374.45h-53.34c-85.23 5.12-110.14 38.26-110.24 100.78l-.06 25.8v139.11c0 51.21-27.44 104.27-86 153.85C106.52 838.7 49.36 889.26 0 929.81V937h850z";
    currentProps.currentViewbox = "0 0 850 937";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M1124.22 448.93V212.16l.09-43.93C1124.44 71 1154.13 15.47 1254 0h666v-200h-2920V937H602.43c61.39-13.12 241.88-72.79 396.74-226.22 85.17-84.37 125.05-174.69 125.05-261.85z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    if (!currentShape.current) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = gsap.fromTo(
        currentShape.current,
        {
          x: 0,
          y: 0,
        },
        {
          x: 700,
          y: 120,
          duration: 0.6,
          ease: "power1.out",
          // transformOrigin: "206% 100%",
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.restart();
    } else {
      currentTween.current.pause();
      gsap.fromTo(
        currentShape.current,
        {
          x: 700,
          y: 120,
        },
        {
          x: 0,
          y: 0,
          duration: 0.4,
          ease: "power1.out",
        }
      );
    }
  }, [rowState, currTranslate]);
  return (
    <svg
      className={classes}
      style={{
        position: "relative",
        pointerEvents: "none",
      }}
      opacity={0.9}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      overflow="visible"
      viewBox={currentProps.currentViewbox}
    >
      <path
        style={{
          pointerEvents: "all",
        }}
        ref={currentShape}
        d={currentProps.currentD}
        fill={shapeColor ? shapeColor : "#fff"}
      />
    </svg>
  );
};
