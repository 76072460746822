import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();

  if (screenRes.width <= 768) {
    currentProps.currentD = "M0 0 h100v100h-100z";
    currentProps.currentViewbox = "0 0 100 100";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD = "M-800 0h1920v937h-1920z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: 800,
        ease: "power1.out",
      }
    );
  }, []);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;
    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={currentProps.currentAspect}
      viewBox={currentProps.currentViewbox}
    >
      <defs>
        <linearGradient
          id="rectangularGradient"
          x1="800"
          y1="468.5"
          x2="2720"
          y2="468.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#74001d" />
          <stop offset="1" stopColor="#ba0027" />
        </linearGradient>
      </defs>
      <path
        ref={currentShape}
        fill={shapeColor ? shapeColor : "url(#rectangularGradient)"}
        d={currentProps.currentD}
      />
    </svg>
  );
};
