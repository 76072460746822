import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { css } from "@emotion/react";
import Media, { useMedia } from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 1;
    height: 150px;
    top: 60% !important;
  }
`;
const secondPath = css`
  position: absolute;
  top: 0px;
  height: 61%;
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const xsMedia = useMedia({ query: "(max-width: 480px)" });

  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: 1100,
        ease: "power1.out",
      }
    );
  }, []);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;
    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio={xsMedia ? "none" : "xMinYMid slice"}
              viewBox={xsMedia ? "0 0 375.14 179.94" : "0 0 768 179.94"}
            >
              <defs>
                <linearGradient
                  id="trapezoid-gradient-rev"
                  x1="186.79"
                  y1="25.72"
                  x2="188.11"
                  y2="346.09"
                  gradientTransform="translate(0 -1.06)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#73001f" />
                  <stop offset=".66" stopColor="#b8002c" />
                  <stop offset=".92" stopColor="#b8002c" />
                </linearGradient>
              </defs>
              <path
                d={
                  xsMedia
                    ? "M275.14 109.87a100 100 0 00100-100V0H0v179.94c19.61-48.64 48.52-70.06 75.47-70.07z"
                    : "M163.12 28H768V0H0v179.94C42.38 74.81 104.87 28 163.12 28z"
                }
                ref={currentShape}
                fill={shapeColor ? shapeColor : "#b8002c"}
              />
            </svg>
            <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <path
                d="M0,0 H300V300H-300z"
                fill={shapeColor ? shapeColor : "url(#trapezoid-gradient-rev)"}
              />
            </svg>
          </>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <defs>
                <linearGradient
                  id="trapezoid-gradient-rev"
                  x1="-1000"
                  y1="468.5"
                  x2="1281"
                  y2="468.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#74001d" />
                  <stop offset="1" stopColor="#ba0027" />
                </linearGradient>
              </defs>
              <path
                d="M567.1 0C556.8.3-1200 0-1200 0v937H408.8c37.9-8.1 149.3-45 244.9-139.8 52.6-52.1 77.2-108 77.2-161.8V366.1l.1-66.1c.1-65.8 22.4-104.6 99-110h47.9c237.5 0 306.5-57 403.1-190H567.1z"
                ref={currentShape}
                opacity=".9"
                fill={shapeColor ? shapeColor : "url(#trapezoid-gradient-rev)"}
              />
            </svg>
          )
      }
    </Media>
  );
};
