import React, { useState, useContext, useCallback, useRef } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { navigate } from "@reach/router";
import { composedPath } from "../../utils/composedPath";
import { useMedia } from "react-media";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { RowContext } from "../../context/row-context";
import { PageContext } from "../../context/page-context";

import Share from "../../assets/svg/share.inline.svg";
import AtSign from "../../assets/svg/at-sign.inline.svg";
import LinkedIn from "../../assets/svg/linkedin.inline.svg";
import Facebook from "../../assets/svg/facebook.inline.svg";

import {
  leftBarPagerow,
  leftBarNews,
  LeftBarLine,
  LeftBarWrap,
  LeftButton,
  TitleWrap,
  Title,
  ShareWrap,
  ShareContainer,
  shareIconWrap,
  shareIcon,
} from "./Components";

gsap.registerPlugin(ScrollToPlugin);

var getNextSibling = function (elem, selector) {
  // Get the next sibling element
  var sibling = elem.nextElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

const onTabletClickScroll = (e) => {
  // e.persist();
  const nextRow = getNextSibling(e.target.closest(".kti-row"), ".kti-row");
  if (nextRow) {
    gsap.to(window, { scrollTo: { y: nextRow.offsetTop } });
  }
};

const onTabletClickBackTop = () => {
  gsap.to(window, { scrollTo: { y: 0 } });
};

const LeftBar = (props) => {
  const {
    template = "pagerow",
    contentTitle,
    linkTo,
    barColor,
    titleColor,
  } = props;
  const [showShare, setShowShare] = useState(false);
  const shareRef = useRef();
  const isTablet = useMedia({
    query: "(max-width: 1365px) and (min-width: 769px)",
  });

  const { jumpTo } = useContext(PageContext);

  const {
    leftBarLinkTo,
    linkToText,
    shouldScrollDown,
    leftBarButton,
  } = useContext(RowContext);
  const currentLinkTo = leftBarLinkTo || linkTo;

  const buttons = {
    scroll: {
      onClick: isTablet ? onTabletClickScroll : shouldScrollDown,
      text: "Scroll",
    },
    "go-back": {
      onClick: () => navigate(-1),
      text: "Go Back",
    },
    "back-to-top": {
      onClick: isTablet ? onTabletClickBackTop : () => jumpTo(0),
      text: "Back to top",
    },
  };

  const shareBodyClick = useCallback(
    (ev) => {
      const currentPath =
        ev.path ||
        (ev.composedPath && ev.composedPath()) ||
        composedPath(ev.target);

      if (currentPath.filter((el) => el === shareRef.current).length === 0) {
        document.body.removeEventListener("click", shareBodyClick);
        setShowShare(false);
      }
    },
    [shareRef, setShowShare]
  );

  const onClickShare = () => {
    if (!showShare) {
      document.body.addEventListener("click", shareBodyClick);
    } else {
      document.body.removeEventListener("click", shareBodyClick);
    }
    setShowShare(!showShare);
  };

  const onClickLink = (e) => {
    e.preventDefault();
    window.open(
      e.currentTarget.href,
      "_blank",
      "height=700,width=500,top=300,left=300"
    );
  };

  const currentLink = typeof window !== "undefined" ? window.location.href : "";

  return (
    <div
      className="leftbar-pagerow"
      css={template === "pagerow" ? leftBarPagerow : leftBarNews}
    >
      <LeftBarWrap
        className={classnames({
          leftBarWrap: true,
          "no-anim": template === "news",
        })}
      >
        <svg
          className="left-bar-svg"
          css={LeftBarLine}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20.46 2000.2"
          preserveAspectRatio="xMidYMax slice"
        >
          <path
            d="M.5 2000.2v-50.93c0-23.11 17.1-17.57 19.44-35.33V0"
            fill="none"
            stroke={barColor}
          />
        </svg>
        {currentLinkTo ? (
          <Link
            className="left-button"
            css={[LeftButton, { color: barColor }]}
            to={currentLinkTo}
            state={{ modal: template === "news" }}
            tabIndex="-1"
          >
            {linkToText}
          </Link>
        ) : (
          <button
            className="left-button"
            css={[LeftButton, { color: barColor }]}
            onClick={buttons[leftBarButton].onClick}
            tabIndex="-1"
          >
            {buttons[leftBarButton].text}
          </button>
        )}
        <ShareWrap className="share-wrap" tabIndex="-1" ref={shareRef}>
          <Share tabIndex="-1" onClick={onClickShare} />
          <ShareContainer className="share-container">
            <div
              className={classnames({ "share-links-shown": showShare })}
              css={shareIconWrap}
            >
              <a
                tabIndex="-1"
                href={`https://www.facebook.com/sharer/sharer.php?u=${currentLink}`}
                onClick={onClickLink}
              >
                <Facebook css={shareIcon} />
              </a>
            </div>
            <div
              className={classnames({ "share-links-shown": showShare })}
              css={shareIconWrap}
            >
              <a
                tabIndex="-1"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentLink}`}
                onClick={onClickLink}
              >
                <LinkedIn css={shareIcon} />
              </a>
            </div>
            <div
              className={classnames({ "share-links-shown": showShare })}
              css={shareIconWrap}
            >
              <a
                tabIndex="-1"
                href={`mailto:?&subject=&body=${currentLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AtSign css={shareIcon} />
              </a>
            </div>
          </ShareContainer>
        </ShareWrap>
      </LeftBarWrap>
      {contentTitle !== "" ? (
        <TitleWrap className="content-title-wrap">
          <Title
            className={classnames({ contentTitle: template === "pagerow" })}
            color={titleColor}
          >
            <span style={{ verticalAlign: "text-bottom" }}>{contentTitle}</span>
          </Title>
        </TitleWrap>
      ) : (
        ""
      )}
    </div>
  );
};

export default LeftBar;