import React, { useEffect, useRef } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import kute from "kute.js";
import "kute.js/kute-svg";
import Media from "react-media";

const svg = css`
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  overflow: visible;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    opacity: 0.9;
  }
`;
// const secondPath = css`
//   position: absolute;
//   top: 150px;
//   height: 100%;
// `;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();
  const currTranslate = 730 - (screenRes.width - 1366) * 0.407;

  if (screenRes.width <= 550) {
    currentProps.currentD =
      "M850 172.61C743.63 297.47 671.84 374.45 409 374.45h-53.34c-85.23 5.12-110.14 38.26-110.24 100.78l-.06 25.8v139.11c0 51.21-27.44 104.27-86 153.85C106.52 838.7 49.36 889.26 0 929.81V937h850z";
    currentProps.currentViewbox = "0 0 850 937";
    currentProps.currentAspect = "xMidYMid slice";
  } else if (
    screenRes.width <= 850 &&
    screenRes.width / screenRes.height <= 15 / 9
  ) {
    currentProps.currentD =
      "M850 172.61C743.63 297.47 671.84 374.45 409 374.45h-53.34c-85.23 5.12-110.14 38.26-110.24 100.78l-.06 25.8v139.11c0 51.21-27.44 104.27-86 153.85C106.52 838.7 49.36 889.26 0 929.81V937h850z";
    currentProps.currentViewbox = "0 0 850 937";
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M665.4 0c99.93 15.47 130.24 71 130.37 168.23l.1 43.93v236.77c0 87.16 39.91 177.48 125.13 261.85 155 153.43 335.57 213.1 397 226.22h1682V0zM0 937h1318L0 936.63v.37z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  useEffect(() => {
    if (!currentShape.current) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = kute.fromTo(
        currentShape.current,
        { svgTransform: { scale: 1 } },
        { svgTransform: { scale: 1.342 } },
        {
          transformOrigin: ["76%", "0%"],
          easing: "easingCubicOut",
          duration: 600,
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.start();
    } else {
      currentTween.current.stop();
      kute
        .fromTo(
          currentShape.current,
          { svgTransform: { scale: 1.342 } },
          { svgTransform: { scale: 1 } },
          {
            transformOrigin: ["76%", "0%"],
            easing: "easingQuadraticOut",
            duration: 400,
          }
        )
        .start();
    }
  }, [rowState, currTranslate]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <>
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMin slice"
              viewBox="0 0 380 1000"
            >
              <path
                ref={currentShape}
                d="M131.49 124.18h-24.34c-38.9 3.11-50.27 23.24-50.31 61.21v100.17c0 31.1-12.52 63.33-39.25 93.44-5.8 6.49-11.68 13.19-17.59 20v601h380V0c-49.11 76.77-127.72 124.18-248.51 124.18z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
            {/* <svg
              css={secondPath}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 300 300"
            >
              <path
                d="M0,0 H300V300H-300z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg> */}
          </>
        ) : (
            <svg
              className={classes}
              css={svg}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 1920 937"
            >
              <path
                ref={currentShape}
                d="M665.4 0c99.93 15.47 130.24 71 130.37 168.23l.1 43.93v236.77c0 87.16 39.91 177.48 125.13 261.85 155 153.43 335.57 213.1 397 226.22h1682V0zM0 937h1318L0 936.63v.37z"
                fill={shapeColor ? shapeColor : "#fff"}
              />
            </svg>
          )
      }
    </Media>
  );
};
