import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";

const svg = css`
  position: relative;
  pointer-events: none;
  overflow: visible;
  width: 100%;
  height: 100%;
  path {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);
  const currentProps = {};

  const screenRes = useWindowSize();

  if (screenRes.width < 1366) {
    currentProps.currentD = "M1354 0v594c-3 21-24 15-24 43v300h590V0z";
    currentProps.currentViewbox = `0 0 1920 937`;
    currentProps.currentAspect = "none";
  } else {
    currentProps.currentD =
      "M1523.7 0v593.7c-2.9 21.7-23.7 14.9-23.7 43V937h1700V0z";
    currentProps.currentViewbox = "0 0 1920 937";
    currentProps.currentAspect = "none";
  }

  const currTranslate = 1200 - (screenRes.width - 1366) * 0.541516;
  useEffect(() => {
    currentTween.current = gsap.fromTo(
      currentShape.current,
      {
        x: 0,
      },
      {
        x: -currTranslate,
        ease: "power1.out",
      }
    );
  }, [currTranslate]);

  useEffect(() => {
    const reversed = rowState !== "expanded";
    const duration = reversed ? 0.3 : 0.6;

    currentTween.current.reversed(reversed).duration(duration);
  }, [rowState, currTranslate]);

  return (
    <svg
      className={classes}
      css={svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={currentProps.currentViewbox}
      preserveAspectRatio={currentProps.currentAspect}
    >
      <path ref={currentShape} d={currentProps.currentD} fill="#3a383c" />
    </svg>
  );
};
