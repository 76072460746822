import React, { useEffect, useRef } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { css } from "@emotion/react";
import kute from "kute.js";
import "kute.js/kute-svg";

const svg = css`
  position: relative;
  pointer-events: none;
  @media (max-width: 1365px) {
    height: 100%;
  }
  @media (max-width: 1200px) {
    height: 700px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Shape = ({ shapeColor, rowState, classes }) => {
  const currentTween = useRef(null);
  const currentShape = useRef(null);

  const screenRes = useWindowSize();
  const currTranslate =
    screenRes.width > 1920 ? -850 : -1200 + (screenRes.width - 1366) * 0.722;

  const tabletView = screenRes.width <= 1200;
  // const mobileView = screenRes.width <= 850;

  useEffect(() => {
    if (!currentShape.current || tabletView) {
      return;
    }
    if (!currentTween.current) {
      currentTween.current = kute.to(
        currentShape.current,
        { svgTransform: { translate: currTranslate } },
        {
          easing: "easingCubicOut",
          duration: 600,
        }
      );
    }
    if (rowState === "expanded") {
      currentTween.current.start();
    } else if (rowState === "openPosition") {
      currentTween.current.stop();
      kute
        .to(
          currentShape.current,
          { path: "#car-openPosition" },
          {
            easing: "easingQuadraticOut",
            duration: 300,
            morphPrecision: 15,
            morphIndex: 105,
          }
        )
        .start();
    } else if (rowState === "closedPosition") {
      currentTween.current.stop();
      kute
        .to(
          currentShape.current,
          { path: "#closedPosition" },
          {
            easing: "easingQuadraticOut",
            duration: 300,
            morphPrecision: 15,
            morphIndex: 289,
          }
        )
        .start();
    } else {
      currentTween.current.stop();
      kute
        .to(
          currentShape.current,
          { svgTransform: { translate: 0 } },
          {
            easing: "easingCubicOut",
            duration: 600,
          }
        )
        .start();
    }
  }, [rowState, currTranslate, tabletView]);

  return (
    <svg
      className={classes}
      css={svg}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={tabletView ? "xMaxYMin slice" : "none"}
      overflow="visible"
      viewBox="0 0 1920 937"
    >
      {tabletView ? (
        <path
          style={{
            pointerEvents: "all",
            isolation: "isolate",
          }}
          d="M1331 2000h589V0h-370c-118 28-195 32-219 190z"
          ref={currentShape}
          opacity=".9"
          fill={shapeColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              id="trapezoid-gradient"
              x1="800.77"
              y1="302.51"
              x2="1920"
              y2="302.51"
              gradientTransform="matrix(1 0 0 -1 0 771.01)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#74001d" />
              <stop offset="1" stopColor="#ba0027" />
            </linearGradient>
          </defs>
          <path
            style={{
              pointerEvents: "all",
              isolation: "isolate",
            }}
            d="M3300 938.3V.3s-1669.8-.7-1673 0c-118.3 27.3-344.5 159.8-368.7 318.2-11.7 76.4 12.4 288.6-22 321.1-97.6 124.3-368.9-94.3-622.2 298.8z"
            ref={currentShape}
            opacity=".9"
            fill={shapeColor ? shapeColor : "url(#trapezoid-gradient)"}
          />
          <path
            style={{
              visibility: "hidden",
            }}
            d="M1331 938.3h1969V.3s-1596.8-.7-1600 0c-118.3 27.3-344.7 159.8-369 318.2z"
            id="car-openPosition"
            fill="none"
          />
          <path
            style={{ visibility: "hidden" }}
            d="M2827 938.33V.33s-1196.8-.74-1200 0c-118.34 27.28-344.46 159.83-368.72 318.19-11.67 76.38 12.45 288.63-22 321.06-97.56 124.28-368.94-94.25-622.17 298.75z"
            id="car-normal"
            fill="none"
          />
          <path
            style={{ visibility: "hidden" }}
            d="M1920,937.3V0.3c0,0-1467.6-0.7-1470.8,0C330.9,27.6,104.8,160.5,80.5,318.8c-11.7,76.4,12.4,288.6-22,321.1c-97.6,124.3-368.9-95.6-622.2,297.4H1920z"
            id="car-expanded"
          />
        </>
      )}
    </svg>
  );
};
